exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ahorcado-tsx": () => import("./../../../src/pages/ahorcado.tsx" /* webpackChunkName: "component---src-pages-ahorcado-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-utils-index-tsx": () => import("./../../../src/pages/utils/index.tsx" /* webpackChunkName: "component---src-pages-utils-index-tsx" */),
  "component---src-pages-utils-list-index-tsx": () => import("./../../../src/pages/utils/list/index.tsx" /* webpackChunkName: "component---src-pages-utils-list-index-tsx" */),
  "component---src-pages-utils-time-index-tsx": () => import("./../../../src/pages/utils/time/index.tsx" /* webpackChunkName: "component---src-pages-utils-time-index-tsx" */)
}

